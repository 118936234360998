<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title> {{ $t("Statistics") }}</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="30" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t(item.subtitle) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  props: {
    countAdminUsers: {
      required: true,
    },
  },

  data() {
    return {
      statisticsItems: [
        {
          icon: "UserIcon",
          color: "light-primary",
          title: this.countAdminUsers,
          subtitle: "Admin Users",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UsersIcon",
          color: "light-info",
          title: "1",
          subtitle: "Expert Users",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "MonitorIcon",
          color: "light-danger",
          title: "2",
          subtitle: "Client Users",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-success",
          title: "2",
          subtitle: "Client Users",
          customClass: "",
        },
      ],
    };
  },
  mounted() {
    this.statisticsItems = [
      {
        icon: "UserIcon",
        color: "light-primary",
        title: this.countAdminUsers,
        subtitle: this.$i18n.t(`Admin Users`),
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "UsersIcon",
        color: "light-info",
        title: "1",
        subtitle: this.$i18n.t(`Expert Users`),
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "TargetIcon",
        color: "light-danger",
        title: "2",
        subtitle:  this.$i18n.t(`Client Users`),
        customClass: "mb-2 mb-sm-0",
      },
      {
        icon: "ServerIcon",
        color: "light-success",
        title: "2",
        subtitle:  this.$i18n.t(`Client Users`),
        customClass: "",
      },
    ];

  },
};
</script>
