<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('Event Statistic') }}</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <div class="buttonsGroup d-flex align-items-center mr-2">
          <b-button variant="outline-secondary" :class="{ 'activeDate':activeFilter===7}" class="mr-1"
                    @click="lastDays(7)"
          > {{ $t('Last 7 Days') }}
          </b-button>
          <b-button variant="outline-secondary" :class="{ 'activeDate':activeFilter===14}" class="mr-1"
                    @click="lastDays(14)"
          > {{ $t('Last 14 Days') }}
          </b-button>
          <b-button variant="outline-secondary" :class="{ 'activeDate':activeFilter===30}" class="mr-1"
                    @click="lastDays(30)"
          >{{ $t('Last 30 Days') }}
          </b-button>
        </div>
        <div class="flat_pcr d-flex align-items-center">
          <feather-icon
              icon="CalendarIcon"
              size="24"
          />
          <flat-pickr
              @input="onDateChange"
              v-model="rangePicker"
              class="form-control flat-picker bg-transparent border-0"
              placeholder="DD/MM/YYYY"
              :config="flatPickrConfig"
          />
        </div>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <b-card-header class="pb-3 pt-0 ">
        <div class="revenue_wrap d-flex align-items-center">
          <div class="icon_wrap">
            <feather-icon
                icon="DollarSignIcon"
                size="32"
            />
          </div>
          <div class="rev_wrap ml-1">
            <div class="rev_val font-medium-3 font-weight-bold">{{ creditsFromPeriod }} €</div>
            <div class="rev_txt">{{ $t('Revenue') }}</div>
          </div>


        </div>
      </b-card-header>




      <chartjs-component-bar-chart
          ref="radarChart"
          v-if="loaded"
          :height="400"
          :chart-data="chartData"
          :options="chartOptions"
          :date="rangePicker"

      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'
import moment from 'moment'
import {German} from 'flatpickr/dist/l10n/de';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
    BButton
  },

  computed: {
    creditsFromPeriod() {
      return this.chartData.datasets[0].data.reduce(function (sum, current) {
        return sum + current
      }, 0)
    }
  },

  data() {
    return {
      loaded: false,
      flatPickrConfig: {
        dateFormat: 'd/m/Y',
        mode:  'range',time_24hr: true,
        locale: German,

      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Credits',
            data: [],
            backgroundColor: '#78A3A9',
            borderColor: 'transparent',
          },
        ],
      },
      chartOptions: {
        scales: {
          yAxes: [{
            ticks: {
              stepSize: 50,
              beginAtZero: true,

              callback(value) {
                return `${value}  Credits`
              }
            }
          }],
          xAxes: [{
            ticks: {
              callback(value) {
                return moment(value, 'DD.MM')
                    .format('DD/MM')
              }
            }
          }],

        },

        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
      },
      activeFilter: 7,
      startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
      endDate: moment()
          .format('YYYY-MM-DD'),
      rangePicker: [moment()
          .subtract(7, 'days')
          .format('DD-MM-YYYY'), moment()
          .format('DD-MM-YYYY')]
    }
  },
  mounted() {
    this.getChartData(this.startDate, this.endDate)
  },

  methods: {
    lastDays(day) {
      this.rangePicker = moment()
          .subtract(day, 'days')
          .format('DD/MM/YYYY') + ' to ' + moment()
          .format('DD/MM/YYYY')
      this.activeFilter = day
      this.onDateChange()
    },

    onDateChange() {

      let rangeDate = this.rangePicker.split(' ')
      let startDate = moment(rangeDate[0], 'DD/MM/YYYY')
          .format('YYYY-MM-DD')
      let endDate = moment(rangeDate[0], 'DD/MM/YYYY')
          .format('YYYY-MM-DD')
      if (rangeDate[2]) {
        endDate = moment(rangeDate[2], 'DD/MM/YYYY')
            .format('YYYY-MM-DD')
      }
      this.getChartData(startDate, endDate)
    },

    getChartData(start, end) {
      this.$http.get('/eventsamount', {
        params: {
          start_date: start,
          end_date: end
        }
      })
          .then((response) => {

            this.chartData.labels = response.data.labels
            this.chartData.datasets[0].data = response.data.data
            this.loaded = true
            this.$refs.radarChart.rerenderChart()
          })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.activeDate {
  background-color: rgba(130, 134, 139, 0.04);;
}

.icon_wrap {
  background: #DFECEB;
  border-radius: 50%;

  svg {
    stroke: #78A3A9;
  }
}
</style>
