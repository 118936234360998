<template>

  <div>
    <card-statistics-group :count-admin-users.sync="countAdminUsers">
    </card-statistics-group>
   <statistic-credits/>


  </div>
</template>

<script>
import CardStatisticsGroup from "./CardStatisticsGroup.vue";
import StatisticCredits from '@/views/dashboard/credits/StatisticCredits'

export default {
  components: {
    CardStatisticsGroup,
    StatisticCredits
  },

  data() {
    return {
      countAdminUsers: 0,
    };
  },

  methods: {
    getData() {
      this.$http.get("dashboard").then((response) => {
        this.countAdminUsers = response.data.statistics.admin_users;
        this.$emit("update:countAdminUsers", 7);
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
